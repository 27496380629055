<template>
    <div class="hero">
        <div class="kontejner">
            <div class="dan">
                <div class="datum">24.05.2024. Petak</div>  <!-- Dan kada se odrzava  -->
                <div class="vreme" style="font-weight: 700; padding: 10px;"></div> <!-- Dan kada se odrzava  -->
                <div class="predavanja">
                    <div class="predavanje1"><p class="naslov">Timski rad – šta je tim, a šta mit </p><p class="predavac">Luka Milošević</p><p class="opis">Kroz par igara, razgovor, nauku i praksu ćemo pričati o timskom radu - šta predstavlja jedan tim, koji mitovi o timskom radu postoje, koje uloge u timu ljudi instiktivno preuzimaju, kao i koje faze u formiranju tima postoje i šta je bitno tokom njih raditi kako bi tim bio uspešan.</p></div> <!-- O desavanjima  -->
                    <div class="predavanje2"><p class="naslov">Time Management Toolbox </p><p class="predavac">Helena Marković i Sofija Mikarić</p><p class="opis">Koliko često ti zafali još jedan sat u danu ili još jedan dan u sedmici? 
Dobro upravljanje vremenom neophodno je kako bismo na duže staze uspeli da postignemo sve što zacrtamo i budemo produktivni. Neki od alata za uspešno upravljanje svojim vremenom jesu postavljanj prioriteta, asertivna komunikacija i efikasan rad u timu. 
Bilo da si potpuno posvećen/a studijama ili uporedo sa njima i radiš ili nastojiš da istraješ u nekom od svojih hobija, ova radionica pruža ti korisnu teorijsku osnovu, ali i praktične veštine za postizanje zacrtanog.  Prijavi se i maksimalno iskoristi vreme koje ti je na raspolaganju!
</p></div>
                </div>
            </div>

  
            
            <div class="dan">
                <div class="datum">25.05.2024. Subota</div>
                <div class="vreme"></div>
                <div class="predavanja">
                    <div class="predavanje1"><p class="naslov">Postavljanje granica - šta je to, je l’ mi treba pasoš?</p><p class="predavac">Sonja Vojnović i Ermin Mujanović</p><p class="opis">Pridružite nam se na uzbudljivom putovanju emocionalne inteligencije na našoj Soft Skills sesiji posvećenoj postavljanju granica! Očekujte dinamične interaktivne vežbe koje će produbiti vaše razumevanje koncepta granica i kako ih uspešno postaviti. Kroz ove aktivnosti, naučićete ne samo zašto su granice važne, već i kada, kako i na koji način ih postaviti u različitim situacijama.</p></div>
                    <div class="predavanje2"><p class="naslov"></p><p class="predavac"></p><p class="opis">Ova sesija je prilika da istražite svoju emocionalnu inteligenciju i unapredite svoje veštine komunikacije i samosvesti. Diskusije će biti srž ovog iskustva, gde ćemo zajedno razmotriti izazove i strategije postavljanja granica u svakodnevnom životu. Spremite se da osnažite svoj emocionalni repertoar i zakoračite ka boljem razumevanju sebe i drugih!</p></div>
                </div>
            </div>

  
            
            <div class="dan">
                <div class="datum">26.05.2024. Nedelja</div>
                <div class="vreme"></div>
                <div class="predavanja">
                    <div class="predavanje1"><p class="naslov">Prezentuj samopouzdano i opušteno</p><p class="predavac">Anđela Gazdić i Maja Mihić</p><p class="opis">Kažu: “Prvo te vide, a zatim te čuju”. Na ovoj sesiji ćemo se baviti veštinom prezentovanja i kako da impresionirate svoju publiku. Upoznaćemo neke od korisnih tehnika koje imaju za cilj da poboljšaju javni nastup, a samim time i podignu nivo samopouzdanja prilikom izlaganja. Očekujemo vas!</p></div>
                    <div class="predavanje2"><p class="naslov"></p><p class="predavac"></p><p class="opis"></p></div>
                </div>
            </div>

  
            




        </div>
    </div>
</template>

<style scoped>

.hero{
        background-image: url("../assets/home_hero_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        height: 100%;
        left: 0;
        right: 0;
        font-size: 17px;        
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .kontejner{

        display: block;
        margin-left: 15px;
        margin-right: 15px;
    }

    .dan {
        margin-bottom: 40px;
        max-width: 800px;
        height: 100%;
        border: 1px solid black;
        border-radius: 20px;
        overflow: hidden;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: 5px 5px 4px rgba(0, 0, 0, 3);
        margin-left: auto;
        margin-right: auto;
    }

    .datum {
        background-color: #24888E;
        font-weight: 700;
        padding: 5px;
    }
    
    .vreme {
        padding: 5px;
        background-color: #C8EFE1;
    }

    .predavanja {
        display: flex;
        justify-content: space-around;
    }

    .predavanja div {
        justify-content: center;
        width: 50%;
        padding: 10px 20px;
    }
    

    .predavanje1{
        background-color: #11998E;
    }

    .predavanje2{
        background-color: #38EF7D;
    }

    .naslov{
        font-weight: 700;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 20px;
        line-height: 1;
    }

    .predavac{
        font-weight: 600;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .opis{
        text-align: justify;
    }

@media (max-width: 1100px) {
    .hero {
        padding-top: 120px;
    }
}

@media (max-width: 768px) {
    .hero {
        font-size: 14px;
    }

    .naslov{
        font-size: 16px;
    }
}

@media (max-width: 479px) { 

    .naslov{
        font-size: 14px;
    }

    .hero{
        font-size: 12px;
    }

    .predavanja div {
        padding: 5px 10px;
    }
}

</style>
