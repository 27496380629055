<template>
    <div class="hero">
        <div class="container">
            <div class="trener">
                <img class="img-left" :src="imgAndjela" alt="">
                <p class="ime">Anđela Gazdić</p>
                <p class="biografija"> Student sam master studija na Elektrotehničkom fakultetu u Beogradu. Već na prvoj sesiji mekih veština kojoj sam prisustvovala prepoznala sam važnost istih. Kako je vreme prolazilo, uvidela sam da je ta vrsta znanja vrlo primenljiva i profesionalno i privatno, te da doprinosi prosperitetu. To me podstaklo da i sama postanem trener mekih veština i podelim svoje iskustvo.</p>
            </div>


            <div class="trener">
                <img class="img-right" :src="imgMaja"  alt="" >
                <p class="ime">Maja Mihić</p>
                <p class="biografija">Zdravo, moje ime je Maja. Trenutno sam apsolvent na Fakultetu tehničkih nauka na smeru Računarstvo i automatika. Studentske dane provodim istraživajući različite oblasti i oprobavajući se na raznim projektima u želji da unapredim svoje meke veštine, upoznam nove ljude i steknem iskustvo. Moj entuzijazam za ličnim razvojem, usavršavanjem, ali i deljenjem znanja sa drugim ljudima odveo me je do toga da postanem trener, 2023. godine na internacionalnom događaju „Training for Trainers“. Najdraže su mi teme koje su usko vezane za emocionalnu inteligenciju.
</p>
            </div>


            <div class="trener">
                <img class="img-left" :src="imgErmin" alt="">
                <p class="ime">Ermin Mujanović</p>
                <p class="biografija">Pozdrav, Moje ime je Ermin i dolazim iz Tuzle sa fakulteta elektrotehnike te kao alumni član lokalnog komiteta Tuzla. Moja karijera u aktivizmu je počela baš jednim soft skills treningom i idejom da želim i ja biti sa druge strane…sada kada je vrieme za to došlo, vredno je spomenuti i ono što se desilo na putu postanka Soft Skills trenera. Naime, svoju EESTEC karijeru sam počeo kao kontakt osoba u Tuzli, kao koordinator lokalnih timova, koordinator projekta te kao šlag na tortu kao predsednik ogranka u Tuzli. Kasnije, kao iskusniji član sam se fokusirao na internacionalni deo asocijacije, gde sam bio koordinator OWL tima i Lider Trening Tima, koji su oba usko vezana sa mekim veštinama te treninzima i sesijama u globalu, pa samim tim moja ljubav za njima postaje sve veća, a najbolji način za jačanje ili buđenje istih, kao prvi korak te avanture jeste baš Soft Skills Academy...ko zna, možda baš i ti budeš ovaj put sa jedne...a uskoro i sa druge strane, vidimo se!
</p>
            </div>


            <div class="trener">
                <img class="img-right" :src="imgSonja" alt="">
                <p class="ime">Sonja Vojnović</p>
                <p class="biografija">Uživam u držanju treninga mekih veština i prenosu znanja na druge ljude. Kao studentu Elektrotehničkog fakulteta u Beogradu, rad na mekim veštinama je upravo ono što nisam mogla da istražujem u sklopu studija a ono što sam pronašla u internacionalnom trening timu u Isteku,  koji mi je omogućio da delim znanje sa ljudima širom cele Evrope. Volim da istražujem nove teme a trenutni fokus mi je na emocionalnoj inteligenciji.
</p>
            </div>

            <div class="trener">
                <img class="img-left" :src="imgLuka" alt="">
                <p class="ime">Luka Milošević</p>
                <p class="biografija">Moje ime je Luka i trenutno sam na poziciji Employer Branding Senior Specijaliste. Direktno sa mladima radim više od 4 godine, od razumevanja potreba svake generacije do organizovanja kompanijskih programa za mlade. Završio sam smer psihologije rada na Filozofskom fakultetu u Beogradu. Jedno od interesovanja mi je psihologija timskog rada – pa sam je tokom studija dublje izučavao.
</p>
            </div>


            <div class="trener">
                <img class="img-right":src="imgHelena" alt="">
                <p class="ime">Helena Marković</p>
                <p class="biografija">Moje ime je Helena Marković i radim u kompaniji Yettel na poziciji specijaliste za organizacionu kulturu. Trenutno pohađam master studije psihologije na Filozofskom fakultetu u Novom Sadu. U budućnosti želim da primenim svoja psihološka znanja u oblasti treninga i razvoja zaposlenih. Slobodno vreme volim da provodi slušajući podkaste u prirodi i volontirajući na različitim konferencijama i događajima.</p>
            </div>

            <div class="trener">
                <img class="img-left":src="imgSofija"  alt="">
                <p class="ime">Sofija Mikarić</p>
                <p class="biografija">Moje ime je Sofija i osnovne studije psihologije sam završila na Filozofskom Fakultetu u Beogradu. Trenutno sam studentkinja master studija psihologije na Filozofskom fakultetu u Novom Sadu, a u kompaniji Yettel Serbia na poziciji Talent Acquisition Specialist Intern. Moja profesionalna interesovanja obuhvataju teme poput motivacije, emocija i  socijalne podrške. Slobodno vreme najradije posvećujem plesu, prijateljima, a kada vreme to dozvoli i putovanjima.</p>
            </div>

            <div class="trener">
                <img class="img-right" :src="imgNevena" alt="">
                <p class="ime">Nevena Tomić</p>
                <p class="biografija">Ja sam Nevena Tomić, student 4. godine Elektrotehničkog fakultetu u Beogradu. Tokom studija, preuzela sam na sebe različite volonterske uloge, uključujući koordinisanje studentskih projekata i rad u Upravnom odboru lokalnog komiteta Udruženja studenata elektrotehnike Evrope. Ova iskustva su mi pomogla da prepoznam značaj mekih veština za izgradnju zdravih međuljudskih odnosa i postizanje ličnih i profesionalnih ciljeva. Kao trener mekih veština, trudim se da podržim sve u kontinualnom radu na sebi. Kroz interaktivne sesije se nadam da ću inspirisati druge da daju prioritet razvijanju korisnih veština koje će ih pripremiti za uspeh u raznim aspektima života.</p>
            </div>




        </div>

    </div>

</template>

<script>
export default{

    data(){
        return{
            imgAndjela: require("../assets/treneri/Andjela.jpg"),
            imgNevena: require("../assets/treneri/Nevena.jpg"),
            imgErmin: require("../assets/treneri/Ermin.jpg"),
            imgHelena: require("../assets/treneri/Helena.jpg"),
            imgLuka: require("../assets/treneri/Luka.jpg"),
            imgMaja: require("../assets/treneri/Maja.jpg"),
            imgSofija: require("../assets/treneri/Sofija.jpg"),
            imgSonja: require("../assets/treneri/Sonja.jpg"),
        }
    }
}





</script>

<style scoped>
    .container{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .hero{
        background-image: url("../assets/home_hero_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        height: 100%;
        left: 0;
        right: 0;
        font-size: 17px;        
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .trener{
        border: 5px solid #11998E;
        height: 100%;
        width: 45%;
        background-color: #38EF7D;
        margin-bottom: 20px;
        border-radius: 20px;
        overflow: hidden;
    }

    .trener{

        margin-top: 15px;
    }

    .trener .img-left{
        float: left;        
        margin-right: 20px;
        margin-bottom: 5px;
    }
    .trener .img-right{
        float: right;
        margin-left: 20px;
        margin-bottom: 5px;
    }
    .ime{
        font-size: 20px;
        font-weight: 700;
    }
    
    .biografija{
        text-align: justify;
        margin: 15px;
    }

@media (max-width: 991px) {
    .container{
        justify-content: center;
    }
    .trener{
        width: 80%;
        margin-top: 40px;
    }
}

@media (max-width: 768px) {
    .trener{
        margin-top: 0px;
        margin-bottom: 20px;
    }
}

@media (max-width: 479px) {

    .trener{
        width: min-content;
    }

    .trener .img-left{
        float: none;
    }
    .trener .img-right{
        float: none;
    }

    .trener img{
        margin: 10px;
        border-radius: 20px 20px 0px 0px;
    }

    .ime{
        margin: 0px;
        font-size: 15px;
    }

    .biografija{
        font-size: 12px;
    }
}
</style>