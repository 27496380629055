<template>
    <Modal @closeModal="ToggleModal" v-if="showModal" />
    <a target="_blank"
        href="https://docs.google.com/forms/d/e/1FAIpQLSeFkmvIHM7ufaNZTJPK99tEMcng_fsMMrHeS7eX-v7tpfekjQ/viewform"> 
        <button :style="{ ...style, fontSize: !mobile ? {} : '20px' }" @click="ToggleModal">
            <slot>
            </slot>
        </button>
    </a> 
</template>



<style scoped>
button {
    font-size: 25px;
    color: black;
    font-weight: bold;
    padding: 12px 20px 12px 20px;
    background: linear-gradient(to right, #38EF7D, #11998E, #38EF7D);
    border-radius: 50px;
    cursor: pointer;
    background-position: left;
    background-size: 200%;
    transition: background 0.5s ease-in-out, transform 0.3s ease-in-out;
}


button:hover {
    background-position: right;
}
</style>