<template>
    <div style="background-color: #11998E;">
        <div class="kontakt container">


            
            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgTeodora" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Sara Gojković</p>
                    <p>Glavni organizator</p>
                    <p style="width: 100%;">glavni_organizator@softskillsacademy.rs</p>
                </Card> 
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Sara Gojković</p>
                    <p>Glavni organizator</p>
                    <p style="width: 100%;">glavni_organizator@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgMilos" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Dunja Štulić</p>
                    <p>Koordinator za publikacije</p>
                    <p style="width: 100%;">publikacije@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Dunja Štulić</p>
                    <p>Koordinator za publikacije</p>
                    <p style="width: 100%;">publikacije@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgSara" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Sara Srećković</p>
                    <p>Koordinator za odnose sa javnošću</p>
                    <p style="width: 100%;">pr@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Sara Srećković</p>
                    <p>Koordinator za odnose sa javnošću</p>
                    <p style="width: 100%;">pr@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgBogdan" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Pavle Timić</p>
                    <p>Koordinator za logistiku</p>
                    <p style="width: 100%;">logistika@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Pavle Timić</p>
                    <p>Koordinator za logistiku</p>
                    <p style="width: 100%;">logistika@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgKatarina" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Dušan Milićević</p>
                    <p>Koordinator za promocije</p>
                    <p style="width: 100%;">promocije@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Dušan Milićević</p>
                    <p>Koordinator za promocije</p>
                    <p style="width: 100%;">promocije@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgTomislav" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Milica Đurđević</p>
                    <p>Koordinator za odnose sa privredom</p>
                    <p style="width: 100%;">fr@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Milica Đurđević</p>
                    <p>Koordinator za odnose sa privredom</p>
                    <p style="width: 100%;">fr@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgMarko" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Rajko Lapčević</p>
                    <p>IT koordinator</p>
                    <p style="width: 100%;">it@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Rajko Lapčević</p>
                    <p>IT koordinator</p>
                    <p style="width: 100%;">it@softskillsacademy.rs</p>
                </div>
            </div>









            <!-- <div class="cardWrapper">
                <Card style="position: absolute; z-index: 999;" :photoUrl="img" :text="img"/> 
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Teodora Vucurevic</p>
                    <p>Glavni organizator</p>
                    <p style="width: 100%;">glavni_organizator@softskillsacademy.rs</p>
                </div>
            </div> -->


            <!-- <div class="cardWrapper2">
                <Card class="img2" style="position: absolute; z-index: 997;" :photoUrl="img" :text="img"/> 
                <div class="info2">
                    <p style="font-size: 20px; font-weight: bold;">Teodora Vucurevic</p>
                    <p>Glavni organizator</p>
                    <p style="width: 100%;">glavni_organizator@softskillsacademy.rs</p>
                </div>
            </div> -->

            <!-- <div class="cardWrapper">
                <Card style="position: absolute; z-index: 999;" :photoUrl="img" :text="img"/> 
                <div class="info">
                    sdassdad
                </div>
            </div> -->


        </div>

    </div>

</template>

<script>
import Card from "../components/ContactCard.vue"

export default{
    components: {Card},
    data (){
        return{
            img: require("../assets/oNama_bg.png"),
            imgTeodora: require("../assets/kontakt/GaraSSA 1-min.png"),
            imgMilos: require("../assets/kontakt/DunjaSSA 1-min.png"),
            imgSara: require("../assets/kontakt/SaraSSA 1-min.png"),
            imgBogdan: require("../assets/kontakt/PavleSSA 1-min.png"),
            imgKatarina: require("../assets/kontakt/DusanSSA 1-min.png"),
            imgTomislav: require("../assets/kontakt/MiciSSA 1-min.png"),
            imgMarko: require("../assets/kontakt/RajkoSSA 1-min.png"),

        }
    }

}

</script>



<style>
.kontakt{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 700px;
    padding-top: 9%;
    padding-bottom: 80px;
}

/*  */

.info {
    background-color: #38EF7D;
    opacity: 0.9;
    border: 2px solid white;
    position: absolute;
    transition: transform 0.5s ease;
    height: 280px;
    width: 180px;
    overflow-wrap: break-word;
    padding: 10px;
    z-index: 998;
    transform: translate(0px, 300px);
}

.cardWrapper {
    position: relative;
    height: 300px;
    width: 200px;
    overflow: hidden;
    margin: 20px 30px;
    
}

.cardWrapper:hover .info{
    transform: translate(0px, 0px);

}


@media (max-width: 992px) {
    .cardWrapper {
        pointer-events: none;
    }
}

@media (max-width: 479px){
    .cardWrapper {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 350px){
    .cardWrapper { 
        margin: 5px 5px;
    }
}



/* .info {

    background-color: #38EF7D;
    border: 2px solid white;
    position: absolute;
    transition: transform 0.3s ease;
    height: 280px;
    width: 180px;
    overflow-wrap: break-word;
    padding: 10px;
}


.cardWrapper {
    position: relative;
    height: 300px;
    width: 200px;
}

.cardWrapper:hover .info{
    transform: translate(200px, 0px);

}

.info2 {

    background-color: #38EF7D;
    border: 2px solid white;
    position: absolute;
    transition: transform 0.3s ease;
    height: 280px;
    width: 180px;
    overflow-wrap: break-word;
    padding: 10px;
    z-index: 996;
}

.img2{
    transition: transform 0.6s ease;
}

.cardWrapper2 {
    position: relative;
    height: 300px;
    width: 200px;
    overflow: hidden;
}

.cardWrapper2:hover .img2{
    transform: translate(0px, -302px);

} */
</style>