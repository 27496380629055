<template>
    <div class="contact-card">
        <img :src='photoUrl' :alt="img">
        <div class="card-info">
            <slot style="position: absolute;" ></slot>
        </div>
    </div>
</template>

<script>
export default{
    props: ['text', "photoUrl"]
}

</script>


<style>
.contact-card{
    background-color: #38EF7D;
    border: 2px solid white;
}

.contact-card img{
    height: 100%;
    width: 100%;
    vertical-align: top;
    object-fit: cover;
}

.card-info {
    display: none;
}

@media (max-width: 992px) {
    .card-info{
        display: block;
        position: absolute;
        bottom: 0px;
        width: 200px;
        background-color: #38EF7D;
        opacity: 0.9;
        overflow-wrap: break-word;
    }
    .card-info p {
        margin: 5px;
        font-size: 12px;
    }
}

</style>