<template>
    <div id="column">
        <div v-if="$slots.left" class="columns"> <!-- v-if is for responsive-->
            <slot name="left"></slot>
        </div>
        <div v-if="$slots.right" class="columns">
            <slot name="right"></slot>
        </div>
    </div>
</template>


<style scoped>
#column {
    display: flex;
}

.columns{
    width: 50%;
    max-width: auto;
}

@media (max-width: 992px) {
    .columns{
        width: 100%;
    }
}


</style>